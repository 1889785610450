<script setup lang="ts">
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const localePath = useLocalePath();

const truFacebook = t('footer.brand.socials.tru.facebook');
const truInstagram = t('footer.brand.socials.tru.instagram');
const truYoutube = t('footer.brand.socials.tru.youtube');
const pntFacebook = t('footer.brand.socials.pnt.facebook');
const pntInstagram = t('footer.brand.socials.pnt.instagram');
const pntYoutube = t('footer.brand.socials.pnt.youtube');

</script>
<template>
  <div class="bg-gradient-to-r from-20% from-[#E72B6F] to-[#1450A0] to-80%">
    <div
      class="container px-5 py-14 flex flex-col md:flex-row items-center justify-between text-[#F3F2EA] text-center md:text-left space-y-10 md:space-y-0">
      <div class="flex flex-col md:flex-row md:space-x-10 space-y-8 md:space-y-0 items-center">
        <nuxt-link :to="localePath('/')">
          <nuxt-img src="images/logo-footer.png" alt="logo" class="h-28" />
        </nuxt-link>
        <div class="border-t md:border-t-0 md:border-l space-y-3 text-xs uppercase pt-7 md:pt-0 md:pl-10 flex flex-col">
          <NuxtLinkLocale class="cursor-pointer" :to="localePath('family_card')" target="_blank">
            {{ t('footer.navigation.familyCardInfo') }}
          </NuxtLinkLocale>
          <NuxtLinkLocale class="cursor-pointer" :to="localePath('family_card_vip')" target="_blank">
            {{ t('footer.navigation.familyVipInfo') }}
          </NuxtLinkLocale>
          <NuxtLinkLocale class="cursor-pointer" :to="localePath('promocion')" target="_blank">
            {{ t('footer.navigation.promotions') }}
          </NuxtLinkLocale>
          <NuxtLinkLocale class="cursor-pointer" :to="localePath('vip-club')" target="_blank">
            {{ t('footer.navigation.vipClub') }}
          </NuxtLinkLocale>
        </div>
      </div>
      <div>
        <div class="flex flex-col md:flex-row space-y-12 md:space-y-0 md:space-x-20">
          <div>
            <nuxt-img src="images/tru-white.png" alt="facebook-icon" format="webp" class="h-10 w-auto mb-2" />
            <div class="flex items-center justify-center space-x-5">
              <nuxt-link :to="truFacebook" target="_blank">
                <nuxt-img src="images/facebook.svg" alt="facebook-icon" format="webp" class="w-6 h-6" />
              </nuxt-link>
              <nuxt-link :to="truInstagram" target="_blank">
                <nuxt-img src="images/instagram.svg" alt="instagram-icon" format="webp" class="w-6 h-6" />
              </nuxt-link>
              <nuxt-link :to="truYoutube" target="_blank">
                <nuxt-img src="images/youtube.svg" alt="youtube-icon" format="webp" class="w-6 h-6" />
              </nuxt-link>
            </div>
          </div>
          <div>
            <nuxt-img src="images/pnt-white.png" alt="facebook-icon" format="webp" class="h-10 w-auto mb-2" />
            <div class="flex items-center justify-center space-x-5">
              <nuxt-link :to="pntFacebook" target="_blank">
                <nuxt-img src="images/facebook.svg" alt="facebook-icon" format="webp" class="w-6 h-6" />
              </nuxt-link>
              <nuxt-link :to="pntInstagram" target="_blank">
                <nuxt-img src="images/instagram.svg" alt="instagram-icon" format="webp" class="w-6 h-6" />
              </nuxt-link>
              <nuxt-link :to="pntYoutube" target="_blank">
                <nuxt-img src="images/youtube.svg" alt="youtube-icon" format="webp" class="w-6 h-6" />
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
